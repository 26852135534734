<template>
  <div>
    <!-- Title page -->
    <div class="mx-auto mb-2">
      <b-row class="justify-content-between align-items-center">
        <b-col sm="4">
          <h1 class="text-primary font-weight-bolder text-uppercase ml-1 my-0">Risque</h1>
        </b-col>
        <!--  -->
        <b-col sm="4" >
          <div class="custom-search d-flex justify-content-end">
          <b-button   v-b-toggle.sidebar-1 @click="addrisque()"
            variant="primary"
            size="md" 
            >
            <feather-icon icon="PlusIcon"/>
            <span class="align-middle">  Ajouter risque</span>
          </b-button>
          </div>
        </b-col>
      <b-sidebar  id="sidebar-1" ref="sideBarRisque"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        shadow
        backdrop
        no-header
        right>
        <template>
          <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
            <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">Nouveau Risque</h5>
            <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide"/>
          </div>
          <validation-observer ref="risqueAdd">
          <b-form class="p-2"  @reset.prevent="resetForm">
            <b-overlay :show="showLoading" no-wrap />
            <!-- -------------------------- -->
            <!-- <b-form-group label="prefixe*" label-for="prefixe" >
              <validation-provider #default="{ errors }" name="prefixe" rules="required">
              <b-form-input class="text-uppercase" v-model="composeData.prefixe" id="prefixe" placeholder="entre prefixe" />
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group> -->
            <!-- -------------------------- -->
            <b-form-group label="Label*"  label-for="label">
              <validation-provider #default="{ errors }" name="label" rules="required">
            <b-form-input  v-model="composeData.label" id="label" placeholder="Entre label" />
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <!-- -------------------------- -->
            <b-form-group label="Type*"  label-for="type">
              <validation-provider #default="{ errors }" name="type" rules="required">
            <b-form-input  v-model="composeData.type" id="type" placeholder="Entre type" />
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <!-- -------------------------- -->
            <b-form-group label="Profil*" label-for="profil">
            <validation-provider #default="{ errors }" name="profil" rules="required">
             <b-form-select
                    v-model="composeData.profil"
                    :options="profil"
                    id="profil"
                    :state="errors.length > 0 ? false : null"
                    />
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
            </b-form-group>
            <b-button
            @click="handleSubmit"
              variant="primary"
              size="md"  >
              <span class="align-middle">  Ajouter risque</span>
            </b-button>
          </b-form>
        </validation-observer>
        </template>
      </b-sidebar>
      </b-row>
      <b-sidebar  id="sidebar-2" ref="sideBarRisqueModifer"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        shadow
        backdrop
        no-header
        right>
        <template>
          <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
            <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">Modifier Risque</h5>
            <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide"/>
          </div>
          <validation-observer ref="risqueEdit">
          <b-form class="p-2"  @reset.prevent="resetForm">
            <b-overlay :show="showLoading" no-wrap />
            <!-- ------------------------- -->
            <!-- <b-form-group label="prefixe*" label-for="prefixe" >
              <validation-provider #default="{ errors }" name="prefixe" rules="required">
              <b-form-input class="text-uppercase" v-model="composeData.prefixe" id="prefixe" placeholder="entre prefixe" />
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>

              </validation-provider>
            </b-form-group> -->
            <!-- -------------------------- -->
            
            <b-form-group label="label*"  label-for="label">
              <validation-provider #default="{ errors }" name="label" rules="required">
            <b-form-input  v-model="composeData.label"  id="label" placeholder="Entre label" />
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <!-- -------------------------- -->
            <b-form-group label="type*"  label-for="type">
              <validation-provider #default="{ errors }" name="type" rules="required">
            <b-form-input  v-model="composeData.type" id="type" placeholder="Entre type" />
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <!-- -------------------------- -->
            <b-form-group label="profil*" label-for="profil">
            <validation-provider #default="{ errors }" name="profil" rules="required">
             <b-form-select
                    v-model="composeData.profil"
                    :options="profil"
                    id="profil"
                    :state="errors.length > 0 ? false : null"
                    />
            </validation-provider>
            </b-form-group>

            <b-button
            @click="modifierRisque()"
              variant="primary"
              size="md"  >
              <span class="align-middle">  Modifer risque</span>
            </b-button>
          </b-form>
        </validation-observer>
        </template>
      </b-sidebar>
    </div>
    
    <b-overlay :show="showLoading" no-wrap />
    <vue-good-table
      style-class="vgt-table condensed"
      class="custom-table-bottom"
      :columns="risqueColumns"
      @on-selected-rows-change="selectionChanged"
            :rows="rowsRisque"
            :rtl="direction"
            :search-options="{
          placeholder:'Rechercher',
          enabled: true,
          externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        mode: 'records',
        perPage: pageLength,
        rowsPerPageLabel: '',
        dropdownAllowAll: false,
        perPageDropdownEnabled: false,
        nextLabel: 'Suivant',
        prevLabel: 'Précédent',
        ofLabel: 'de',
        infoFn: params => ``
      }"
    >
    <template slot="table-row" slot-scope="props">
        <!-- Column: Action -->
        <span v-if="props.column.field === 'id'">
          <span class="cursor-pointer">
            <b-button   v-b-toggle.sidebar-2 
            size="20"
            class="mr-50"
            variant="none"
            @click="functionid(props.row.id,props.row.label,props.row.profil,props.row.type)"
            >
            <feather-icon size="20" icon="EditIcon"  class="mr-50" />
            </b-button>
          </span>
        </span>
      </template>
      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <b-row class="mt-2 align-items-center">
              <b-col md="6" lg="5" xxl="3">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Affichage 1 à
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['100','500','1000']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> de {{ props.total }} entrées </span>
                </div>
              </b-col>
          </b-row>
        <b-row class="mt-2 align-items-center">
          <b-col>
            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" @input="value => props.pageChanged({ currentPage: value })">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </template>
      <div slot="emptystate" class="text-center align-middle">
        <span>Aucune donn&eacute;e disponible dans le tableau</span>
      </div>
    </vue-good-table>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import {BInputGroupAppend, BFormSelectOption, VBTooltip, BFormInvalidFeedback, BInputGroupPrepend, BFormFile, BFormInput, BFormGroup, BPagination, BForm, VBToggle, BRow, BCol, BDropdown, BDropdownItem, BButton, BCard, BCardText, BCardHeader, BMedia, BImg, BOverlay, BFormSelect, BSidebar, BFormTextarea, BInputGroup, BTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
export default {
  components: {
    BFormSelectOption,
    BFormInvalidFeedback,
    VBToggle,
    BInputGroupPrepend,
    BFormFile,
    BFormInput,
    BFormGroup,
    BForm,
    BInputGroupAppend,
    BFormTextarea,
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BSidebar,
    BButton,
    BCard,
    BCardText,
    BCardHeader,
    BMedia,
    BImg,
    BOverlay,
    VueGoodTable,
    BPagination,
    BFormSelect,
    ToastificationContentVue
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
        return {
        showLoading: false,
        pageLength: 100,
        dir: false,
        renderComponent: true,
        modalAction: 'Nouveau',
        rowsRisque: [],
        filter: {
          recherche: null
        },
        risqueColumns: [
            // { label: 'prefixe', field: 'prefixe', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking', tdClass: 'text-center m-0 p-2 align-middle' },
            { label: 'label', field: 'label', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking', tdClass: 'text-center m-0 p-2 align-middle' },
            { label: 'type', field: 'type', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking', tdClass: 'text-center m-0 p-2 align-middle' },
            { label: 'profil', field: 'profil', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking', tdClass: 'text-center m-0 p-2 align-middle' },
            { label: 'Modification', field: 'id', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking', tdClass: 'text-center m-0 p-2 align-middle' }
          ],
        currentUser: JSON.parse(localStorage.getItem('userData')),
        composeData: {
        id:null,
        prefixe: null,
        label: null,
        type: null,
        profil: null,
      },
      profil:[
            { value: null, text: 'Choisissez' },
            { value: 'PARTICULIER', text: 'PARTICULIER' },
            { value: 'PROFESSIONNEL', text: 'PROFESSIONNEL' },
            { value: 'ENTREPRISE', text: 'ENTREPRISE' },
      ]
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true
        return this.dir
      }
      this.dir = false
      return this.dir
    }
  },
  created() {
    this.fetchRisque()
  },
  methods: {
    forceRerender() {
      this.renderComponent = false
      this.$nextTick(() => {
        this.renderComponent = true
      })
    },
    hide() {
        this.$refs.sideBarRisque.hide()
        this.$refs.sideBarRisqueModifer.hide()
        this.clearData()

      
    },
    messageToast(text, title, variant) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'BellIcon',
          text,
          variant,
        },
      })
    },
      clearData() {
        this.$refs.risqueEdit.reset()
        this.$refs.risqueAdd.reset()
        this.composeData.id = null
        this.composeData.prefixe = null
        this.composeData.label = null
        this.composeData.type = null
        this.composeData.profil = null
    },
    fetchRisque() {
      this.showLoading = true
      this.rowsRisque = []
      this.$http
          .get(`/risques/fetchRisquesList`)
          .then(res => {
            if (res.data.success) {
              this.rowsRisque = res.data.data
              this.showLoading = false
            } else {
              this.showLoading = false
              this.messageToast('Nous n\'avons pas pu trouver la ressource que vous avez demandée.', 'Erreur', 'warning')
            }
          })
          .catch(err => {
            this.showLoading = false
            this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
            console.error(err)
          })
    },
    addrisque(){
      this.clearData()
    },
    handleSubmit(){
      const formData = new FormData()
          this.$refs.risqueAdd.validate()
          .then(success => {
            if (success) {
            this.showLoading = true
            formData.append('prefixe', this.composeData.prefixe)
            formData.append('label', this.composeData.label)
            formData.append('type', this.composeData.type)
            formData.append('profil', this.composeData.profil)
            const config = { headers: { 'Content-Type': 'multipart/form-data' } }
            this.$http
                  .post('/risques/addRisque', formData, config)
                  .then(res => {
                    if (res.data.success){
                      this.hide()
                      this.fetchRisque()
                      this.showLoading = false
                      this.messageToast('Le risque a bien été enregistré', 'Succès', 'success')
                    } 
                    else {                
                      this.showLoading = false
                      this.messageToast('Erreur lors de creation de nouveau risque.', 'Erreur', 'error')
                    }
                  })
                  .catch(err => {
                    this.showLoading = false
                    this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
                  })
            }
            })
          },
          functionid(id,label,profil,type){ 
            console.log(id,label,profil,type)
            this.composeData.label=label
            this.composeData.profil=profil
            this.composeData.type=type
            this.composeData.id=id
          },
          // getBase64Document(props.row.document_id,
          modifierRisque(){
            const formData = new FormData()
          this.$refs.risqueEdit.validate()
          .then(success => {
            if (success) {
            this.showLoading = true
            formData.append('id', this.composeData.id)
            formData.append('prefixe', this.composeData.prefixe)
            formData.append('label', this.composeData.label)
            formData.append('type', this.composeData.type)
            formData.append('profil', this.composeData.profil)
            const config = { headers: { 'Content-Type': 'multipart/form-data' } }
            this.$http
                  .post('/risques/updateRisque', formData, config)
                  .then(res => {
                    if (res.data.success){
                      this.hide()
                      this.fetchRisque()
                      this.showLoading = false
                      this.messageToast('Le risque a bien été modifié', 'Succès', 'success')
                    } 
                    else {                
                      this.showLoading = false
                      this.messageToast('Erreur lors de modification de nouveau risque.', 'Erreur', 'error')
                    }
                  })
                  .catch(err => {
                    this.showLoading = false
                    this.messageToast(err.response.data.errors[0], 'Erreur', 'error')

                  })
            }
            })
          }
    },
  }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
div.custom-table-bottom >div >.vgt-responsive{
    min-height: 0 !important;
}
</style>